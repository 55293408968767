import React, { useEffect } from 'react';
import Head from 'next/head';

import { useTracker } from '~/app/lib/tracker/useTracker';
import { on } from '~/app/lib/utils/events';
import { OneTrustGroups } from '~/app/lib/oneTrust/types';
import { getIsProduction } from '~/app/lib/getSongwhipEnv';

const mapOneTrustGroup = (group: string) => {
  switch (group) {
    case OneTrustGroups.STRICTLY_NECESSARY:
      return 'strictly_necessary';
    case OneTrustGroups.PERFORMANCE:
      return 'performance';
    case OneTrustGroups.FUNCTIONAL:
      return 'functional';
    case OneTrustGroups.TARGETING:
      return 'targeting';
    case OneTrustGroups.SOCIAL_MEDIA:
      return 'social_media';
    default:
      return group;
  }
};

export const OneTrust = React.memo(() => {
  const { trackEvent } = useTracker();

  useEffect(() => {
    return on(
      window,
      'OneTrustGroupsUpdated',
      (event: CustomEvent<string[]>) => {
        trackEvent(
          {
            type: 'gdpr-consent',
            groups: event.detail.map(mapOneTrustGroup),
          },
          {},
          { skipAnalyticsOptOutCheck: true }
        );
      }
    );
  }, []);

  return (
    <>
      <style jsx global>{`
        /* Re-write our global svg css style to avoid banner svg misplacement */
        #onetrust-consent-sdk svg,
        #ot-sdk-btn-floating svg {
          display: inline;
        }

        .ot-pc-footer-logo,
        #ot-sdk-btn-floating {
          display: none;
        }

        #onetrust-consent-sdk #onetrust-banner-sdk,
        #onetrust-consent-sdk #onetrust-pc-sdk * {
          outline: 0 !important;
        }

        #onetrust-pc-sdk .ot-pc-header,
        #onetrust-pc-sdk .ot-pc-footer,
        #onetrust-pc-sdk .ot-accordion-layout.ot-cat-item {
          border: 0 !important;
        }
      `}</style>
      <Head>
        <script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          data-document-language="true"
          type="text/javascript"
          charSet="UTF-8"
          data-testid="onetrust-script"
          data-domain-script={`50db3430-ad8d-4545-bbba-6ed6d7a6feba${
            getIsProduction() ? '' : '-test'
          }`}
          async
        />
      </Head>
    </>
  );
});
